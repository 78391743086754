<template>
    <div>
      <div class="row">
        <div class="d-flex flex-column justify-content-center align-items-center text-center" style="width: 500px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <img src="/resource/img/collecting_data.svg" alt="collecting_data" style="width: 200px; height: 200px;">
            <h3 class="my-5">We are accumulating statistics</h3>
            <h4>To provide you with a detailed and credible report, we need your child to solve more problems in the game. Please come back soon.</h4>
        </div>
        <!-- <p class="page_name">Главная</p> -->
      </div>
    </div>
</template>

<script>
// import axios from "axios"
import router from "@/router"
var rldata = {
  user: {
    _id: '',
    childs: []
  }
}
export default {
  name: "Dashboard",
  data () {
    return {
      rldata
    }
  },
  methods: {
  },
  mounted () {
    try{
        this.$root.getUserData(this.rldata);
      } catch(err){
    //     console.log(err);
        this.$root.logout();
      }
  },
   watch: {
     "rldata.user": function(handler){
       if(handler != null) {

        // switch(rldata.user.gr){
        //   case "GR_PARENT":
        //     if(rldata.user.childs.length == 0){
        //       router.push("/parents/child_connect");
        //     }
        //     break;

        //   default:
        //   console.log('none');
        // }

       }
     }
   }
}
</script>